
import { Component, Vue, Watch } from "vue-property-decorator";
import FinancingStepGroup from "./components/FinancingStepGroup/FinancingStepGroup.vue";
import PersonStepGroup from "./components/PersonStepGroup/PersonStepGroup.vue";
import SimulationFinishNegative from "./components/SimulationStepGroup/SimulationFinishNegative.vue";
import SimulationFinishPositive from "./components/SimulationStepGroup/SimulationFinishPositive.vue";
import SimulationFutureStepGroup from "./components/SimulationStepGroup/SimulationFutureStepGroup.vue";
import SimulationStepGroup from "./components/SimulationStepGroup/SimulationStepGroup.vue";
import SubmissionProposalGroup from "./components/SubmissionProposalGroup/SubmissionProposalGroup.vue";
import VehicleStepGroup from "./components/VehicleStepGroup/VehicleStepGroup.vue";
import WizardFooter from "./components/WizardFooter.vue";
import WizardHeader from "./components/WizardHeader.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CAuthenticationFailed from "../../components/fields-step/CAuthenticationFailed/CAuthenticationFailed.vue";
import CTokenExpired from "../../components/fields-step/CTokenExpired/CTokenExpired.vue";

import useAuth from "@/services/auth/useAuth";
import useAppConfigStore from "@/store/appConfig";
import useAuthStore from "@/store/auth";
import {
  createPageGoogleAnalytics,
  leavePageTag,
  updateGoogleAnalytics,
} from "@/utils/updateGoogleAnalytics";

@Component({
  name: "Wizard",
  components: {
    WizardHeader,
    PersonStepGroup,
    WizardFooter,
    VehicleStepGroup,
    FinancingStepGroup,
    Loading,
    SimulationStepGroup,
    SimulationFutureStepGroup,
    CTokenExpired,
    CAuthenticationFailed,
    SimulationFinishPositive,
    SubmissionProposalGroup,
    SimulationFinishNegative,
  },
})
export default class Wizard extends Vue {
  //Store
  appConfigStore = useAppConfigStore();
  authStore = useAuthStore();

  //Parâmetros finais
  parcelValue: number = 1;
  approved: boolean | undefined = undefined;

  //Variáveis auxiliares para resultados da requisição
  colorMain: string = "#2F2F2F";
  colorMainFont: string = "#FFFFFF";
  defaultImage: string = require("@/assets/logo.png");
  successRequest: boolean = false;
  displayHeader: boolean = false;

  get ehApresentarSteps(): boolean {
    return (
      this.successRequest &&
      !this.appConfigStore.carregandoServicosVeiculosIniciais
    );
  }

  get ehLoading(): boolean {
    return this.appConfigStore.loading || !this.ehApresentarSteps;
  }

  /**
   * Função utilizada para inicializar configurações do google analytics
   */
  @Watch("valueGoogle")
  updateGoogleAnalytics(): void {
    const selfGtag = this.$gtag;
    updateGoogleAnalytics(selfGtag);
  }

  /**
   * Variável auxiliar para retornar o total de steps atuais
   */
  get valueGoogle(): any {
    return this.appConfigStore.totalStepCurrent;
  }

  /**
   * Criar pagina do step de falha na autenticação no google  analytics
   */
  @Watch("valueGoogleAuthenticate")
  updateGoogleAnalyticsAuthenticate(): void {
    if (this.appConfigStore.authenticationFailed) {
      const selfGtag = this.$gtag;
      createPageGoogleAnalytics(selfGtag, "Autenticação Falha");
    }
  }

  /**
   * Variável auxiliar para retornar se autenticação é vállida
   */
  get valueGoogleAuthenticate(): any {
    return this.appConfigStore.authenticationFailed;
  }

  /**
   * Método padrão vue para
   */
  async created(): Promise<void> {
    const uri = window.location.search.substring(2);
    const params = new URLSearchParams(uri);
    let windowAccess = params.get("windowAccess");
    if (!windowAccess) {
      windowAccess = useAuth.getWindowAccess();
    }
    if (windowAccess == "blank") {
      this.appConfigStore.$patch((state) => (state.buttonAdvance = false));
    }

    const uriToken = window.location.search.substring(1);
    const paramsToken = new URLSearchParams(uriToken);
    let guid = paramsToken.get("token");

    if (!guid) {
      guid = useAuth.getGuid();
    }

    this.appConfigStore.$patch((state) => (state.loading = true));

    await this.authStore
      .fetchLogin(guid ? guid : "")
      .then((response) => {
        const token = response.data.retorno!.tokenAcesso!;
        useAuth.setToken(token);

        const pontoVendaId = response.data.retorno!.pontoVendaId!;
        useAuth.setPontoVendaId(pontoVendaId);

        const urlFandi = response.data.retorno!.urlFandi!;
        useAuth.setUrlFandi(urlFandi);

        this.authStore.setDataFormResponse(response.data.retorno!);

        return response.data.retorno;
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.message != null &&
            error.response.data.message != undefined
          ) {
            this.appConfigStore.$patch(
              (state) =>
                (state.messageAuthenticationFailed =
                  error.response.data.message)
            );
          }
        }

        this.appConfigStore.$patch(
          (state) => (state.authenticationFailed = true)
        );
      })
      .finally(() =>
        this.appConfigStore.$patch((state) => (state.loading = false))
      );

    // Adiciona evento de saída da página
    window.addEventListener("beforeunload", async () => {
      const pageView = document
      .getElementsByClassName("current")[0]
      .getAttribute("data-page-view");

      leavePageTag(this.$gtag, pageView || "undefined");
    });
  }

  /**
   * Método padrão do vue para carregar quantidade de steps
   */
  beforeUpdate(): void {
    var amountStep1 = document.getElementById("personstep")
      ? document.getElementById("personstep")!.getElementsByClassName("step")
          .length
      : 0;

    var amountStep2 = document.getElementById("vehiclestep")
      ? document.getElementById("vehiclestep")!.getElementsByClassName("step")
          .length
      : 0;

    var amountStep3 = document.getElementById("financingstep")
      ? document.getElementById("financingstep")!.getElementsByClassName("step")
          .length
      : 0;

    var totalStep = amountStep1 + amountStep2 + amountStep3;

    this.appConfigStore.$patch(
      (state) => (state.valueStepPerson = amountStep1)
    );
    this.appConfigStore.$patch(
      (state) => (state.valueStepVehicle = amountStep2)
    );
    this.appConfigStore.$patch(
      (state) => (state.valueStepFinancing = amountStep3)
    );
    this.appConfigStore.$patch((state) => (state.valueStepTotal = totalStep));
  }

  updated(): void {
    this.displayHeader = true;
  }

  /**
   * Função urilizada para carregar estilos quando requisição for terminada
   */
  @Watch("storeDataFormResponse", { immediate: true })
  updateRequisitionStyle(): void {
    Promise.resolve().then(() => {
      if (!this.authStore.dataFormResponse.config) {
        return;
      }

      if (this.authStore.dataFormResponse.config.corDestaque) {
        this.colorMain = this.authStore.dataFormResponse.config.corDestaque!;
      }
      if (this.authStore.dataFormResponse.config.corFonteBotao) {
        this.colorMainFont =
          this.authStore.dataFormResponse.config.corFonteBotao!;
      }
      if (this.authStore.dataFormResponse.config.urlLogomarca) {
        this.defaultImage =
          this.authStore.dataFormResponse.config.urlLogomarca!;
      }
      this.appConfigStore.$patch((state) => (state.loading = false));
      this.successRequest = true;
    });
  }

  /*
   * Função para esconder mensagem de errro
   */
  alertHide(): void {
    document
      .getElementsByClassName("alert")[0]
      .setAttribute("style", "display: none");
    document.getElementsByClassName("alert-text")[0].textContent = "";
  }

  /**
   * Variável auxiliar para retornar resposta da requisição de obter guide
   */
  get storeDataFormResponse(): any {
    return this.authStore.dataFormResponse;
  }
}
