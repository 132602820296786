
import { Component, Vue, Watch } from "vue-property-decorator";

// Components
import CBoxConfirmed from "@/components/fields-step/CBoxConfirmed/CBoxConfirmed.vue";
import CInput from "@/components/fields-step/CInput/CInput.vue";
import CInputDate from "@/components/fields-step/CInputDate/InputDate.vue";
import CRadioButton from "@/components/fields-step/CRadioButton/CRadioButton.vue";
import CRadioButtonList from "@/components/fields-step/CRadioButtonList/CRadioButtonList.vue";
import CTermOfUse from "@/components/fields-step/CTermOfUse/CTermOfUse.vue";

// Interface
import { IDataConfirmed } from "@/components/fields-step/CBoxConfirmed/interface/IDataConfirmed";
import { IRadioButtonListOption } from "@/components/fields-step/CRadioButtonList/interface/IRadioButtonListOption";
import { IFormField } from "./interface/IFormField";
import { IFormPerson } from "./interface/IFormPerson";

import { toggleDisabledElement } from "@/utils/disabledAndActivated";
import {
  removeStyleError,
  validateBirthDate,
  validateCelphone,
  validateCpf,
  validateEmail,
  validateFullName,
} from "@/utils/validations";
import moment from "moment";

//Store
import useAppConfigStore from "@/store/appConfig";
import useAuthStore from "@/store/auth";

@Component({
  name: "PersonStepGroup",
  components: {
    CInputDate,
    CInput,
    CRadioButtonList,
    CRadioButton,
    CTermOfUse,
    CBoxConfirmed,
  },
})
export default class PersonStepGroup extends Vue {
  appConfigStore = useAppConfigStore();
  authStore = useAuthStore();

  // Rules
  showCommercialPurposes: boolean = true;
  showPCD: boolean = true;

  //variável que armazena id button radio
  idButtonRadioClick = "";

  // Rules
  boxConfirmedPerson: boolean = false;

  formPerson = {
    fullName: {} as IFormField,
    gender: {} as IFormField,
    dateOfBirth: {} as IFormField,
    hasCNH: {} as IFormField,
    commercialPurposes: {} as IFormField,
    isPCD: {} as IFormField,
    CPF: {} as IFormField,
    email: {} as IFormField,
    celphone: {} as IFormField,
  } as IFormPerson;

  optionsGender: Array<IRadioButtonListOption> = [
    { id: "genderF", text: "Feminino", value: "Feminino" },
    { id: "genderM", text: "Masculino", value: "Masculino" },
  ];

  optionsCNH: Array<IRadioButtonListOption> = [
    { id: "CNH-T", text: "Sim", value: "Sim" },
    { id: "CNH-F", text: "Não", value: "Não" },
  ];

  optionsCommercialPurposes: Array<IRadioButtonListOption> = [
    { id: "commercialPurposes-T", text: "Sim", value: "Sim" },
    { id: "commercialPurposes-F", text: "Não", value: "Não" },
  ];

  optionsisPCD: Array<IRadioButtonListOption> = [
    { id: "isPCD-T", text: "Sim", value: "Sim" },
    { id: "isPCD-F", text: "Não", value: "Não" },
  ];

  /**
   * Função que faz o dos dados do step conforme dados requisitados no banco
   */
  @Watch("storeDataFormResponse", { immediate: true })
  initializePersonStepGrou(): void {
    if (this.authStore.dataFormResponse.cliente) {
      if (this.authStore.dataFormResponse.cliente.nome) {
        this.formPerson.fullName.value =
          this.authStore.dataFormResponse.cliente.nome!;
        this.formPerson.fullName.external = true;
        this.formPerson.fullName.valid = true;
      }

      if (this.authStore.dataFormResponse.cliente.sexo) {
        if (this.authStore.dataFormResponse.cliente.sexo === "F") {
          this.formPerson.gender.value = "Feminino";
        } else {
          this.formPerson.gender.value = "Masculino";
        }
        this.formPerson.gender.external = true;
        this.formPerson.gender.valid = true;
      }

      if (
        this.authStore.dataFormResponse.cliente.dataNascimento &&
        this.authStore.dataFormResponse.cliente.dataNascimento !=
          "0001-01-01T00:00:00"
      ) {
        let date = this.authStore.dataFormResponse.cliente.dataNascimento!;
        let dateFormated = date.substr(0, date.indexOf("T"));
        this.formPerson.dateOfBirth.value = dateFormated;
        this.formPerson.dateOfBirth.external = true;
        this.formPerson.dateOfBirth.valid = true;
      }

      if (
        this.authStore.dataFormResponse.cliente.possuiCnh === false ||
        this.authStore.dataFormResponse.cliente.possuiCnh === true
      ) {
        let possuiCNHaux = this.authStore.dataFormResponse.cliente.possuiCnh;

        if (possuiCNHaux) {
          this.formPerson.hasCNH.value = "Sim";
        } else {
          this.formPerson.hasCNH.value = "Não";
        }
        this.formPerson.hasCNH.external = true;
        this.formPerson.hasCNH.valid = true;
      }

      if (
        this.authStore.dataFormResponse.cliente.usoTaxi === false ||
        this.authStore.dataFormResponse.cliente.usoTaxi === true
      ) {
        if (this.authStore.dataFormResponse.cliente.usoTaxi) {
          this.formPerson.commercialPurposes.value = "Sim";
        } else {
          this.formPerson.commercialPurposes.value = "Não";
        }
        this.formPerson.commercialPurposes.external = true;
        this.formPerson.commercialPurposes.valid = true;
      }

      if (
        this.authStore.dataFormResponse.cliente.pcd === false ||
        this.authStore.dataFormResponse.cliente.pcd === true
      ) {
        if (this.authStore.dataFormResponse.cliente.pcd) {
          this.formPerson.isPCD.value = "Sim";
        } else {
          this.formPerson.isPCD.value = "Não";
        }
        this.formPerson.isPCD.external = true;
        this.formPerson.isPCD.valid = true;
      }

      if (this.authStore.dataFormResponse.cliente.cpf) {
        this.formPerson.CPF.value =
          this.authStore.dataFormResponse.cliente.cpf!;
        this.formPerson.CPF.external = true;
        this.formPerson.CPF.valid = true;
      }

      if (this.authStore.dataFormResponse.cliente.email) {
        this.formPerson.email.value =
          this.authStore.dataFormResponse.cliente.email!;
        this.formPerson.email.external = true;
        this.formPerson.email.valid = true;
      }

      if (this.authStore.dataFormResponse.cliente.celular) {
        this.formPerson.celphone.value =
          this.authStore.dataFormResponse.cliente.celular!;
        this.formPerson.celphone.external = true;
        this.formPerson.celphone.valid = true;
      }

      if (
        this.authStore.dataFormResponse.config.confirmarDados.includes(
          "ConfirmarDadosPessoais"
        )
      ) {
        this.boxConfirmedPerson = true;
      }
    }
    this.appConfigStore.$patch(
      (state) => (state.dataFormPerson = this.formPerson)
    );
  }

  /**
   * Método padrão do vue para ao iniciar form
   */
  beforeMount(): void {
    this.initializeForm();
  }

  /**
   * Função para inicialiar formulário do grupo Person
   */
  initializeForm(): void {
    const defaultInitialize: IFormField = {
      value: "",
      external: false,
      valid: false,
      messageError: "⚠ Campo obrigatório!",
    };

    defaultInitialize.messageError = "⚠ Campo obrigatório!";
    this.formPerson.fullName = Object.assign({}, defaultInitialize);

    defaultInitialize.messageError =
      "⚠ Selecione ao menos uma opção para prosseguir!";
    this.formPerson.gender = Object.assign({}, defaultInitialize);

    defaultInitialize.messageError = "⚠ Campo obrigatório!";
    this.formPerson.dateOfBirth = Object.assign({}, defaultInitialize);

    defaultInitialize.messageError =
      "⚠ Selecione ao menos uma opção para prosseguir!";
    this.formPerson.hasCNH = Object.assign({}, defaultInitialize);

    defaultInitialize.messageError =
      "⚠ Selecione ao menos uma opção para prosseguir!";
    this.formPerson.commercialPurposes = Object.assign({}, defaultInitialize);

    defaultInitialize.messageError =
      "⚠ Selecione ao menos uma opção para prosseguir!";
    this.formPerson.isPCD = Object.assign({}, defaultInitialize);

    defaultInitialize.messageError = "⚠ O CPF informado não é válido!";
    this.formPerson.CPF = Object.assign({}, defaultInitialize);

    defaultInitialize.messageError = "⚠ O email informado não é válido!";
    this.formPerson.email = Object.assign({}, defaultInitialize);

    defaultInitialize.messageError =
      "⚠ O número de celular informado não é válido!";
    this.formPerson.celphone = Object.assign({}, defaultInitialize);
  }

  // Utils component
  removeItemsDataConfirmed(
    array: Array<any>,
    itemToRemove: Array<string>
  ): Array<any> {
    return array.filter((v) => {
      return !itemToRemove.includes(v.titleData);
    });
  }

  /**
   * Função que valida o input de nome
   * @param event string
   */
  validInputName(event: string): void {
    this.formPerson.fullName.value = event;

    //removendo notificação de campo com erro
    removeStyleError();

    //Valida se é vazio
    if (!this.formPerson.fullName.value) {
      this.formPerson.fullName.valid = false;
      this.formPerson.fullName.messageError = "⚠ Campo obrigatório!";
      return;
    }
    //Valida se posui dois nomes
    if (!validateFullName(this.formPerson.fullName.value)) {
      this.formPerson.fullName.valid = false;
      this.formPerson.fullName.messageError =
        "⚠ Informe o nome completo para prosseguir!";
      return;
    }

    this.formPerson.fullName.valid = true;
  }

  /**
   * Função que valida o radio é válido
   * @param event string
   */
  validRadioGender(event: string): void {
    this.formPerson.gender.value = event;
    this.formPerson.gender.valid = true;

    //removendo notificação de campo com erro
    removeStyleError();

    this.appConfigStore.$patch(
      (state) =>
        (state.activeActionButton = !this.appConfigStore.activeActionButton)
    );
  }

  /**
   * Função que valida se o radio foi selecionado
   * @param event string
   */
  validInputDate(event: string): void {
    this.formPerson.dateOfBirth.value = event;
    this.formPerson.dateOfBirth.valid = false;

    //removendo notificação de campo com erro
    removeStyleError();
    this.appConfigStore.$patch((state) => (state.valid = true));

    //Valida se o campo foi preenchido
    if (!this.formPerson.dateOfBirth.value) {
      this.formPerson.dateOfBirth.messageError = "⚠ Campo obrigatório!";
      return;
    }

    if (!validateBirthDate(this.formPerson.dateOfBirth.value)) {
      this.formPerson.dateOfBirth.messageError =
        "⚠ A data informada não é válida!";
      return;
    }

    this.formPerson.dateOfBirth.valid = true;
  }

  /**
   * Função que valida se o radio foi selecionado
   * @param event string
   */
  validRadioCNH(event: string): void {
    this.formPerson.hasCNH.value = event;
    this.formPerson.hasCNH.valid = true;

    //removendo notificação de campo com erro
    removeStyleError();

    const elementRadioCNH = document.querySelector(
      "input[type=radio][name=CNH]:checked"
    );
    if (elementRadioCNH) {
      this.idButtonRadioClick = elementRadioCNH.id;
    }

    this.appConfigStore.$patch(
      (state) =>
        (state.activeActionButton = !this.appConfigStore.activeActionButton)
    );
  }

  /**
   * Função que valida se o radio foi selecionado
   * @param event string
   */
  validRadioComercialPurposes(event: string): void {
    this.formPerson.commercialPurposes.value = event;
    this.formPerson.commercialPurposes.valid = true;

    //removendo notificação de campo com erro
    removeStyleError();

    const elementRadioComercial = document.querySelector(
      "input[type=radio][name=commercialPurposes]:checked"
    );

    if (elementRadioComercial) {
      this.idButtonRadioClick = elementRadioComercial.id;
      toggleDisabledElement(elementRadioComercial.id, true);
    }

    this.appConfigStore.$patch(
      (state) =>
        (state.activeActionButton = !this.appConfigStore.activeActionButton)
    );
    if (elementRadioComercial) {
      toggleDisabledElement(elementRadioComercial.id, false);
    }
  }

  validRadioIsPCD(event: string): void {
    this.formPerson.isPCD.value = event;
    this.formPerson.isPCD.valid = true;

    //removendo notificação de campo com erro
    removeStyleError();

    const elementRadioPCD = document.querySelector(
      "input[type=radio][name=isPCD]:checked"
    );

    if (elementRadioPCD) {
      this.idButtonRadioClick = elementRadioPCD.id;
      toggleDisabledElement(elementRadioPCD.id, true);
    }

    this.appConfigStore.$patch(
      (state) =>
        (state.activeActionButton = !this.appConfigStore.activeActionButton)
    );
    if (elementRadioPCD) {
      toggleDisabledElement(elementRadioPCD.id, false);
    }
  }

  validInputCPF(event: string): void {
    this.formPerson.CPF.value = event;
    this.formPerson.CPF.valid = false;

    //removendo notificação de campo com erro
    removeStyleError();

    //Valida se o campo foi preenchido
    if (!this.formPerson.CPF.value) {
      return;
    }

    //Valida se o CPF é válido
    if (!validateCpf(this.formPerson.CPF.value)) {
      return;
    }

    this.formPerson.CPF.valid = true;
  }

  validInputEmail(event: string): void {
    this.formPerson.email.value = event;
    this.formPerson.email.valid = false;

    //removendo notificação de campo com erro
    removeStyleError();

    //Valida se o campo foi preenchido
    if (!this.formPerson.email.value) {
      return;
    }

    //Valida se o email é válido
    if (!validateEmail(this.formPerson.email.value)) {
      return;
    }
    this.formPerson.email.valid = true;
  }

  validInputCelphone(event: string): void {
    this.formPerson.celphone.value = event;
    this.formPerson.celphone.valid = false;

    //removendo notificação de campo com erro
    removeStyleError();

    //Valida se o campo foi preenchido
    if (!this.formPerson.celphone.value) {
      return;
    }

    if (!validateCelphone(this.formPerson.celphone.value)) {
      return;
    }
    this.formPerson.celphone.valid = true;
  }

  /**
   * Variável auxiliar para retornar resposta de requisição
   */
  get storeDataFormResponse(): any {
    return this.authStore.dataFormResponse;
  }

  /**
   * Função para apresentar step
   * quando botão CONCORDO E DESEJO PROSSEGUIR
   * estiver em tela
   */
  get displayValue(): string {
    if (
      this.appConfigStore.isPersonStep &&
      !this.appConfigStore.displayConfirm &&
      (this.appConfigStore.totalStepCurrent === 0 ||
        (!(this.appConfigStore.valueStepCurrentForGroup === 0) &&
          this.appConfigStore.valueStepCurrentForGroup + 1 ===
            this.appConfigStore.valueStepCurrent) ||
        this.appConfigStore.totalStepCurrent ===
          this.appConfigStore.valueStepTotal)
    ) {
      return "display: block";
    }
    return "display: none";
  }

  getFormatDate(): any {
    let date = JSON.stringify(this.formPerson.dateOfBirth.value);
    date = JSON.parse(date);
    return moment(date).format("DD/MM/YYYY");
  }

  getDateBirth(): any {
    const date =
      this.formPerson.dateOfBirth.external == true
        ? this.getFormatDate()
        : this.formPerson.dateOfBirth.value
            .toString()
            .split("-")
            .reverse()
            .join("/");
    return date;
  }

  get dataConfirmed(): Array<IDataConfirmed> {
    var _dataConfirmed = [] as Array<IDataConfirmed>;

    _dataConfirmed = [
      { titleData: "Nome completo", data: this.formPerson.fullName.value },
      { titleData: "Sexo", data: this.formPerson.gender.value },
      {
        titleData: "Data de nascimento",
        data: this.getDateBirth(),
      },
      { titleData: "Tem CNH", data: this.formPerson.hasCNH.value },
      {
        titleData: "Para fins comerciais",
        data: this.formPerson.commercialPurposes.value,
      },
      { titleData: "PCD", data: this.formPerson.isPCD.value },
      { titleData: "CPF", data: this.formPerson.CPF.value },
      { titleData: "Email", data: this.formPerson.email.value },
      { titleData: "Número do celular", data: this.formPerson.celphone.value },
    ];
    return _dataConfirmed;
  }

  get removedSteps(): Array<string> {
    var _removedSteps: Array<string> = [];

    if (!this.showCommercialPurposes) {
      _removedSteps.push("Para fins comerciais");
    }

    if (!this.showCommercialPurposes) {
      _removedSteps.push("PCD");
    }

    return _removedSteps;
  }

  get _showCommercialPurposes(): boolean {
    if (this.formPerson.commercialPurposes.external) {
      return false;
    }

    if (this.showCommercialPurposes) {
      return true;
    }

    return false;
  }

  get _showPCD(): boolean {
    if (this.formPerson.isPCD.external) {
      return false;
    }

    if (this.showPCD) {
      return true;
    }

    return false;
  }

  /**
   * Retorna se o step será ou não apresentado
   */
  get showStep(): boolean {
    return (
      this.appConfigStore.allActiveSteps || this.appConfigStore.editionSteps
    );
  }
}
