import { VueGtag } from "vue-gtag";

const generateLeadTag = ($gtag: VueGtag) => {
  $gtag.event("generate_lead", {
    event_category: "simular",
    event_label: "Simulação",
  });
};

const confirmConditionTag = ($gtag: VueGtag) => {
  $gtag.event("confirmar_condicao", {
    event_category: "inserir",
    event_label: "Confirmar condição",
  });
};

const resimulationTag = ($gtag: VueGtag) => {
  $gtag.event("resimulacao", {
    event_category: "simular",
    event_label: "Resimulação",
  });
};

const leavePageTag = ($gtag: VueGtag, pageView: string) => {
  $gtag.event("abandona_jornada", {
    event_category: "leave_page",
    event_label: "Abandona jornada",
    value: pageView,
  });
};

const pageViewTag = ($gtag: VueGtag, pageView: string) => {
  $gtag.pageview({ page_title: pageView });
};

const completeRegistration = (
  $gtag: VueGtag,
  shippingType: "email" | "whatsapp" | string
) => {
  $gtag.event("preencher_cadastro", {
    event_category: "enviar_link",
    event_label: "Completar cadastro",
    value: shippingType,
  });
};

const rangeResourceTag = ($gtag: VueGtag) => {
  $gtag.event("recurso_regua", {
    event_category: "recurso",
    event_label: "Régua de entrada",
  });
};

/**
 * Função que transforma um página no google analytics e
 * Conta como mais uma visita caso já a página tenha sido criada
 * @param $gtag VueGtag
 * @param title string
 */
function createPageGoogleAnalytics($gtag: VueGtag, title: string): void {
  $gtag.query("event", "page_view", {
    page_title: title,
  });
}

/**
 * Função que identifica se é o step atual para
 * transformar em página no google analytics
 * @param $gtag VueGtag
 * @param classe string
 * @param title string
 */
function createPageGoogleAnalyticsForStep(
  $gtag: VueGtag,
  classe: string,
  title: string
): void {
  const elementStepAtual = document.getElementsByClassName("current")[0];

  if (
    elementStepAtual
      ? elementStepAtual!.getElementsByClassName(classe).length > 0
      : false
  ) {
    createPageGoogleAnalytics($gtag, title);
  }
}

/**
 * Função utilizada para adicionar foco
 * em elementos label e input
 * @param $gtag VueGtag
 */
function updateGoogleAnalytics($gtag: VueGtag): void {
  /**
   * Autenticação falha
   */
  createPageGoogleAnalyticsForStep(
    $gtag,
    "authenticationFailed",
    "Autenticacao Falha"
  );

  /**
   * Grupo Person
   */
  createPageGoogleAnalyticsForStep($gtag, "TermOfUse", "Termo de uso");
  createPageGoogleAnalyticsForStep($gtag, "fullName", "Nome completo");
  createPageGoogleAnalyticsForStep($gtag, "gender", "Gênero");
  createPageGoogleAnalyticsForStep($gtag, "nascimento", "Nascimento");
  createPageGoogleAnalyticsForStep($gtag, "CNH", "CNH");
  createPageGoogleAnalyticsForStep(
    $gtag,
    "commercialPurposes",
    "Uso comercial"
  );
  createPageGoogleAnalyticsForStep($gtag, "isPCD", "PCD");
  createPageGoogleAnalyticsForStep($gtag, "CPF", "CPF");
  createPageGoogleAnalyticsForStep($gtag, "email", "email");
  createPageGoogleAnalyticsForStep($gtag, "phone", "Celular");
  createPageGoogleAnalyticsForStep(
    $gtag,
    "boxConfirmedPerson",
    "Confirmacao grupo dados pessoais"
  );

  /**
   * Grupo Vehicle
   */
  createPageGoogleAnalyticsForStep(
    $gtag,
    "typeVehicle",
    "Veiculo novo ou usado"
  );
  createPageGoogleAnalyticsForStep(
    $gtag,
    "factureYear",
    "Ano de fabricação"
  );
  createPageGoogleAnalyticsForStep($gtag, "modelYear", "Ano do modelo");
  createPageGoogleAnalyticsForStep(
    $gtag,
    "brandVehicle",
    "Marca do veiculo"
  );
  createPageGoogleAnalyticsForStep(
    $gtag,
    "modelVehicle",
    "Modelo do veiculo"
  );
  createPageGoogleAnalyticsForStep(
    $gtag,
    "versionVehicle",
    "Versao do veiculo"
  );
  createPageGoogleAnalyticsForStep($gtag, "priceVehicle", "Valor veiculo");
  createPageGoogleAnalyticsForStep(
    $gtag,
    "boxConfirmedVehicle",
    "Confirmacao grupo veiculo"
  );

  /**
   * Grupo Financing
   */
  createPageGoogleAnalyticsForStep($gtag, "rangeNumber", "Entrada");
  createPageGoogleAnalyticsForStep($gtag, "parcel", "Parcela");
  createPageGoogleAnalyticsForStep(
    $gtag,
    "boxConfirmedFinancing",
    "Confirmacao grupo finanças"
  );

  /**
   * Simulação
   */
  createPageGoogleAnalyticsForStep(
    $gtag,
    "boxConfirmedSimulacao",
    "Confirmacao grupo simulação"
  );
}export {
  confirmConditionTag,
  completeRegistration,
  createPageGoogleAnalytics,
  createPageGoogleAnalyticsForStep,
  generateLeadTag,
  leavePageTag,
  pageViewTag,
  rangeResourceTag,
  resimulationTag,
  updateGoogleAnalytics,
};

