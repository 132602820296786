
import { Component, Vue } from "vue-property-decorator";

import useAppConfigStore from "@/store/appConfig";
import useAuthStore from "@/store/auth";
import useSimulationStore from "@/store/simulation";
import useVehicleStore from "@/store/vehicle";

@Component
export default class CBoxConfirmed extends Vue {
  vehicleStore = useVehicleStore();
  simulationStore = useSimulationStore();
  appConfigStore = useAppConfigStore();
  authStore = useAuthStore();

  get preApproved(): boolean | undefined {
    if (
      this.simulationStore.dataFormResponseSimulation.retorno ||
      (this.simulationStore.dataFormResponseSimulation.retorno &&
        !this.appConfigStore.isFinishedPositive)
    ) {
      return true;
    } else if (this.simulationStore.dataFormResponseSimulation.message) {
      return false;
    }
    return undefined;
  }

  get formatPercentValue(): string {
    const formatPriceVehicle =
      this.vehicleStore.dataFormVehicle.priceVehicle.value
        .toString()
        .replaceAll(".", "")
        .replaceAll(",", ".");

    const valueVehicle: number = parseFloat(formatPriceVehicle);
    const valueEntry: number =
      typeof this.simulationStore.dataFormResponseSimulation.retorno
        ?.valorEntrada == "number"
        ? this.simulationStore.dataFormResponseSimulation.retorno?.valorEntrada
        : 0;

    if (valueVehicle === 0 || valueEntry === 0) {
      return "0";
    }

    const percent: string = ((100 * valueEntry) / valueVehicle).toFixed(2);

    return percent;
  }

  get valueParcel(): string {
    return this.simulationStore.dataFormResponseSimulation.retorno!.valorParcela!.toLocaleString(
      "pt-br",
      { minimumFractionDigits: 2, maximumFractionDigits: 2}
    );
  }

  get valueEntry(): string {
    return this.simulationStore.dataFormResponseSimulation.retorno!.valorEntrada!.toLocaleString(
      "pt-br",
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    );
  }

  get quantityParcel(): number {
    return this.simulationStore.dataFormResponseSimulation.retorno!
      .quantidadeParcelas!;
  }

  get vehicleManufacturer(): string {
    return this.simulationStore.dataFormResponseSimulation.retorno!.veiculo
      .fabricante!;
  }

  get vehicleFamily(): string {
    return this.simulationStore.dataFormResponseSimulation.retorno!.veiculo
      .familia!;
  }

  get nameClient(): string {
    return typeof this.appConfigStore.dataFormPerson.fullName.value == "string"
      ? this.appConfigStore.dataFormPerson.fullName.value.split(" ")[0]
      : JSON.stringify(this.appConfigStore.dataFormPerson.fullName.value).split(
          " "
        )[0];
  }
}
