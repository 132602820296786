import "@/libs/phosphor/phosphor_1_4_2.css";
import router from "@/router/index";
import useAuth from "@/services/auth/useAuth";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueMask from "v-mask";
import Vue from "vue";
import VueGtag from "vue-gtag";
import App from "./App.vue";
import store from "./store";

/**
 * SCSS TEMPLATE limmo_v_1.2
 */
import "@/scss/style.scss";

Vue.config.productionTip = false;

Vue.use(VueMask);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTHICS },
});

Vue.use(PiniaVuePlugin);

const pinia = createPinia();

/**
 * Intancia para iniciar e montar a aplicação vue
 */
const Instance = new Vue({
  store,
  components: { App },
  router,
  pinia,
  data: () => ({ configCheckout: {} }),
  render: function (createElement) {
    return createElement("app", {
      props: { configCheckout: this.configCheckout },
    });
  },
});

/**
 * Classe com valores passados para a aplicação
 * Monta a aplicação conforme id passado
 */
export default class CheckoutClass {
  constructor(
    selector: string,
    configCheckout: any = { guid: "", windowAccess: undefined }
  ) {
    const guid = configCheckout.guid;
    useAuth.setGuid(guid);
    const windowAccess = configCheckout.windowAccess;
    useAuth.setWindowAccess(windowAccess);

    Vue.set(Instance, "configCheckout", {
      ...configCheckout,

      id: selector.replace("#", ""),
    });
    Instance.$mount(selector);
    return Instance;
  }
}
